<template>
  <base-loader v-if="this.$store.state.moduleStack.subnets.length === 0" />
  <base-table-row
    v-else
    class="tariffs-table-item"
    :columns="columns"
    v-bind="port"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariffs-table-item__link medium-text">
        <div class="hover">
          {{ networkName }}
        </div>
      </div>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template #subnet>
      <div class="tariffs-table-item__ip">
        {{ subnetName }}
      </div>
    </template>
    <template #ip>
      <div class="tariffs-table-item__ip">
        {{ ipRouter }}
      </div>
    </template>
    <template #state>
      <tumbler :value="item.admin_state_up" class="theme-switcher__tumbler" @change="setState" />
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <router-subnet-context-menu
          slot="popover"
          :tariff="port"
          :router="router"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import Tumbler from '@/components/BaseTumbler/Tumbler';
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import RouterSubnetContextMenu from './RouterSubnetContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
export default {
  name: 'TariffsTableRouterViewRow',
  components: {
    BaseTableRow,
    Tumbler,
    // MainLabel,
    RouterSubnetContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    router: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    port() {
      return this.$store.state.moduleStack.ports.find(x => x.id === this.item.id);
    },
    networkName() {
      return this.networkId
        ? this.$store.state.moduleStack.networks.find(x => x.id === this.networkId).name
        : null;
    },
    networkId() {
      return this.port.network_id ? this.port.network_id : null;
    },
    subnetId() {
      return this.port.fixed_ips[0].subnet_id ? this.port.fixed_ips[0].subnet_id : null;
    },
    subnetName() {
      return this.subnetId
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.subnetId).name
        : null;
    },
    ipRouter() {
      return this.port.fixed_ips[0].ip_address ? this.port.fixed_ips[0].ip_address : null;
    },
  },
  methods: {
    printParam(param, payload) {
      return param.toString(payload);
    },
    toInstance(item) {
      const id = item && item.port_details ? item.port_details.device_id : null;
      const name = id ? this.servers.find(i => i.id === id).name : '---';
      console.log(name);
      return item.fixed_ip_address ? `${name} ${item.fixed_ip_address}` : '---';
    },
    viewSubnets(id) {
      // console.log(this.subnets.map(x => x.id));
      // console.log(id);
      return this.subnets.find(i => i.id === id).name;
    },
    setState(value) {
      const payload = {
        port: {
          admin_state_up: value,
        },
        id: this.port.id,
        item: 'admin_state_up',
      };
      this.$store.dispatch('moduleStack/updatePort', payload);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "sure": {
      "confirm": "Подключить",
      "success": "Подсеть добавлена успешно",
      "fail": "Что-то пошло не так",
      "close": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
